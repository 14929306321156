<template>
  <div v-for="(item, index) in list" :key="'chat_' + index">
    <div class="time" v-if="item.type === 'date'">
      {{ item.content }}
    </div>
    <div class="text-center" v-else-if="item.type === 'card'">
      <div class="card close" v-if="item.content === 'close'">
        <div>咨询结束</div>
        <div class="time">{{buildDate(item.time,"yyyy-mm-dd HH:MM:SS")}}</div>
      </div>
    </div>
    <div class="problem" v-else-if="!item.me">
      <img
        class="avatar"
        alt="头像"
        :src="'img/avatar/' + item.avatar + '.png'"
      />
      <div class="triangle"></div>
      <div class="info">
        <div class="nickname">{{ item.name }}</div>
        <div class="message" v-if="item.type === 'text'">
          <div>{{ item.content }}</div>
        </div>
        <el-image
          :preview-src-list="[host + item.content]"
          lazy
          class="image"
          fit="fill"
          :src="host + item.content"
          v-else-if="item.type === 'image'"
        >
          <template #placeholder>
            <div class="el-loading-spinner">
              <svg class="circular" viewBox="25 25 50 50">
                <circle
                  class="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                ></circle>
              </svg>
            </div>
          </template>
        </el-image>
      </div>
    </div>
    <div class="answer" v-else>
      <div class="info">
        <div class="nickname">{{ item.name }}</div>
        <div class="message" v-if="item.type === 'text'">
          <div>{{ item.content }}</div>
        </div>
        <el-image
          :preview-src-list="[host + item.content]"
          lazy
          class="image"
          fit="fill"
          :src="host + item.content"
          v-else-if="item.type === 'image'"
        >
          <template #placeholder>
            <div class="el-loading-spinner">
              <svg class="circular" viewBox="25 25 50 50">
                <circle
                  class="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                ></circle>
              </svg>
            </div>
          </template>
        </el-image>
      </div>
      <div class="triangle"></div>
      <img
        class="avatar"
        alt="头像"
        :src="'img/avatar/' + item.avatar + '.png'"
      />
    </div>
  </div>
</template>

<script>
import { date } from "../../plugin/util";

export default {
  name: "ChatContent",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    host: "",
  }),
  methods: {
    buildDate(data, code) {
      return date.format(data, code);
    },
  },
  mounted() {
    this.host = process.env.VUE_APP_BASE_URL;
  },
};
</script>

<style scoped>
.time {
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.problem,
.answer {
  margin-bottom: 15px;
  display: flex;
}

.answer {
  justify-content: flex-end;
}

.answer .info {
  text-align: right;
}

.avatar {
  background-color: #fff;
  border-radius: 6px;
  padding: 2px;
  height: 36px;
  width: 36px;
}

.triangle {
  border-bottom: 6px solid transparent;
  border-top: 4px solid transparent;
  height: 0;
  width: 0;
}

.problem .triangle {
  border-right: 8px solid #fff;
  margin: 25px 0 0 5px;
}

.answer .triangle {
  border-left: 8px solid #17c8c2;
  margin: 25px 5px 0 0;
}

.info {
  width: calc(100% - 120px);
}

.info .image {
  background-color: #17c8c2;
  border-radius: 8px;
  min-height: 120px;
  min-width: 280px;
  max-width: 50%;
}

.problem .info .image{
  background-color: #fff;
}

.el-loading-spinner .path {
  stroke: #fff;
}

.problem .el-loading-spinner .path {
  stroke: #17c8c2;
}

.nickname {
  margin: 0 5px 2px 5px;
  font-size: 13px;
  color: #999;
}

.answer .nickname {
  text-align: right;
}

.message {
  max-width: calc(100vw - 130px);
  background-color: #fff;
  white-space: pre-wrap;
  display: inline-block;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
}

.answer .message {
  background-color: #17c8c2;
  color: #fff;
}

img.message {
  max-width: calc(100vw - 130px);
  border-radius: 6px;
}

.card{
  display: inline-table;
  box-shadow: none;
}

.card.close{
  font-size: 14px;
}

.close .time{
  margin: 5px 0 0 0;
}
</style>