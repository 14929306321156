<template>
  <div class="container" style="overflow-y: hidden">
    <div class="card mb-0" v-if="!error">
      <div class="chat-head">
        <div class="title">
          <div class="btn" @click="$router.back()">
            <el-icon><arrow-left /></el-icon>
            <span>返回</span>
          </div>
          <span v-if="info.title">{{ info.title }}</span>
        </div>
        <div>
          <el-button
            v-if="patient.id"
            @click="this.$router.push('/patient/' + patient.id)"
            >患者详情</el-button
          >
          <el-button
            :disabled="connect"
            :type="connect ? 'success' : 'danger'"
            @click="initSocket"
            v-if="info.state == 1"
            >{{ connect ? "已连接" : "已断开" }}</el-button
          >
          <el-button
            type="danger"
            :disabled="info && info.state != 1"
            @click="closeChat"
            >结束咨询</el-button
          >
        </div>
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <el-scrollbar
            height="calc(100vh - 185px)"
            style="height: calc(100vh - 185px)"
            ref="talkScrollbar"
          >
            <div id="talk" class="pa-10">
              <div class="text-center mb-10">
                <el-button
                  :disabled="!connect"
                  size="small"
                  plain
                  :loading="loading.talk"
                  @click="getTalk"
                  >{{ loading.talk ? "正在加载中" : "加载更多对话" }}</el-button
                >
              </div>
              <chat-content :list="list" />
            </div>
          </el-scrollbar>
          <div class="bottom-bat" v-if="info.state == 1">
            <el-input
              :disabled="!connect"
              v-model="message"
              resize="none"
              :rows="3"
              type="textarea"
              placeholder="按下 Ctrl + Enter 发送消息..."
              @keydown.ctrl.enter="sendText"
            />
            <div class="bottom-tools">
              <div>
                <el-button
                  :disabled="!connect"
                  style="border: none; width: 100%; height: 30px"
                  @click="selectFile"
                >
                  <el-icon><picture-filled /></el-icon>
                  <span>上传</span>
                </el-button>
              </div>
              <div>
                <el-button
                  class="mt-5"
                  type="primary"
                  size="large"
                  @click="sendText"
                  :disabled="!connect"
                >
                  <el-icon><promotion /></el-icon>
                  <span>发送</span>
                </el-button>
              </div>
            </div>
          </div>
          <div class="bottom-bat off" v-else>会话已关闭</div>
        </div>
        <div class="chat-info" v-loading="loading.info">
          <el-scrollbar height="calc(100vh - 85px)">
            <el-descriptions title="基础信息" :column="2" v-if="patient.id">
              <el-descriptions-item label="姓名" width="175px">{{
                patient.name
              }}</el-descriptions-item>
              <el-descriptions-item label="证件类型">{{
                patient.certTypeName
              }}</el-descriptions-item>
              <el-descriptions-item label="性别">{{
                patient.gender === 1 ? "男" : "女"
              }}</el-descriptions-item>
              <el-descriptions-item label="证件号码">{{
                patient.certId
              }}</el-descriptions-item>
              <el-descriptions-item label="年龄"
                >{{ getAge(patient.birthday) }}岁</el-descriptions-item
              >
              <el-descriptions-item label="联系电话">{{
                info.phone
              }}</el-descriptions-item>
              <el-descriptions-item label="家庭住址">{{
                patient.address
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="移植信息" :column="2" v-if="patient.id">
              <el-descriptions-item label="入院日期" width="175px"
                >-</el-descriptions-item
              >
              <el-descriptions-item label="移植类型">{{
                patient.transplantTypeName
              }}</el-descriptions-item>
              <el-descriptions-item label="出院日期">{{
                buildDate(patient.dischargeDate, "yyyy-mm-dd")
              }}</el-descriptions-item>
              <el-descriptions-item label="手术日期">{{
                buildDate(patient.surgeryDate, "yyyy-mm-dd")
              }}</el-descriptions-item>
              <el-descriptions-item label="出院天数"
                >{{
                  parseFloat(
                    (new Date().getTime() - patient.dischargeDate) /
                      1000 /
                      60 /
                      60 /
                      24
                  ).toFixed(0)
                }}
                天</el-descriptions-item
              >
              <el-descriptions-item label="术后天数"
                >{{
                  parseFloat(
                    (new Date().getTime() - patient.surgeryDate) /
                      1000 /
                      60 /
                      60 /
                      24
                  ).toFixed(0)
                }}
                天</el-descriptions-item
              >
              <el-descriptions-item label="手术医生">{{
                patient.surgeryDoctorName
              }}</el-descriptions-item>
              <el-descriptions-item label="主管医生">{{
                patient.supervisorName
              }}</el-descriptions-item>
              <el-descriptions-item label="伴随疾病">-</el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              title="随访信息"
              :column="1"
              v-if="plan.now || plan.next"
            >
              <el-descriptions-item label="随访进度">
                <span v-if="patient.progress">
                计划 {{ patient.progress.total }} 次 - 完成
                {{ patient.progress.complete }} 次 / 过期
                {{ patient.progress.overdue }} 次
                </span>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item label="上次随访">
                <span class="mr-10">{{
                  plan.now.date ? plan.now.date : "-"
                }}</span>
                <span v-if="plan.next.label">{{ plan.now.label }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="下次随访">
                <span class="mr-10">{{
                  plan.next.date ? plan.next.date : "-"
                }}</span>
                <span v-if="plan.next.label">{{ plan.next.label }}</span>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="日常指标" :column="2" v-if="test.date">
              <template v-for="(item, i) in test.list" :key="i">
                <el-descriptions-item
                  :width="i == 0 ? '175px' : 'auto'"
                  :label="item.label"
                >{{ item.value }}</el-descriptions-item>
              </template>
            </el-descriptions>
            <el-descriptions :column="1">
              <el-descriptions-item label="异常症状">
                {{ test.abnormal ? test.abnormal : "-" }}
              </el-descriptions-item>
              <el-descriptions-item label="其他药物">
                {{ test.drug ? test.drug : "-" }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :column="1" v-if="test.date">
              <el-descriptions-item label="记录时间" width="175px">{{
                buildDate(test.date, "yyyy-mm-dd HH:MM:SS")
              }}</el-descriptions-item>
            </el-descriptions>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <el-result
      v-else
      icon="error"
      title="加载失败"
      style="padding-top: calc(50vh - 120px)"
    />

    <input
      v-show="false"
      ref="fileInput"
      type="file"
      accept=".jpeg,.jpg,.png"
      multiple
      @change="fileChange"
    />
    <div class="loading no-select" :class="{ show: tips.s2 }" v-if="tips.s1">
      <div class="el-loading-spinner" v-if="tips.s2">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
        </svg>
        <div>{{ tips.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeft, PictureFilled, Promotion } from "@element-plus/icons";
import ChatContent from "../../components/chat/content.vue";
import { problem } from "../../plugin/api";
import { date } from "../../plugin/util";
import socket from "../../plugin/socket";

export default {
  name: "ChatInfo",
  components: { ArrowLeft, PictureFilled, Promotion, ChatContent },
  data: () => ({
    id: 0,
    error: false,
    loading: {
      talk: true,
      info: true,
    },
    info: {},
    patient: {},
    plan: {
      now: {},
      next: {},
    },
    test: {},
    list: [],
    task: null,
    connect: false,
    message: "",
    page: 1,
    tips: {
      s1: false,
      s2: false,
      text: "",
    },
  }),
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.queryInfo();
    },
    queryInfo() {
      problem
        .getInfo(this.id)
        .then((res) => {
          setTimeout(() => {
            this.loading.info = false;
            if (res.state) {
              this.info = res.data.info;
              this.plan.now = res.data.now;
              this.plan.next = res.data.next;
              this.patient = res.data.patient;
              this.test = res.data.test;
            } else this.error = true;
          }, 500);
          this.initSocket();
        })
        .catch(() => {
          this.loading.info = false;
          this.error = true;
        });
    },
    buildDate(data, code) {
      return date.format(data, code);
    },
    getAge(number) {
      let now = new Date();
      let birthdays = new Date(parseInt(number) * 1000);
      return (
        now.getFullYear() -
        birthdays.getFullYear() -
        (now.getMonth() < birthdays.getMonth() ||
        (now.getMonth() == birthdays.getMonth() &&
          now.getDate() < birthdays.getDate())
          ? 1
          : 0)
      );
    },
    getTalk() {
      this.loading.talk = true;
      socket.send(this.task, "getTalkList", { page: this.page });
    },
    sendText() {
      let msg = this.message;
      if (msg.length > 0) {
        socket.send(this.task, "talkText", {
          type: "text",
          content: msg,
        });
        this.message = "";
      }
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    toBottom() {
      this.$refs.talkScrollbar.setScrollTop(
        document.getElementById("talk").scrollHeight
      );
    },
    initSocket() {
      this.page = 1;
      this.task = socket.connect();
      socket.init(this.task, this.wsOpen, this.wsError, this.wsClose);
      socket.receive(this.task, this.receive);
    },
    send(action, data) {
      data.action = action;
      this.socket.send(JSON.stringify(data));
    },
    receive(res) {
      switch (res.message) {
        case "getTalkList":
          setTimeout(() => {
            this.loading.talk = false;
            if (res.data.length > 0) {
              if (res.code == 1) {
                this.list = res.data;
                setTimeout(() => {
                  this.toBottom();
                }, 100);
              } else {
                res.data.reverse();
                for (let i in res.data) this.list.unshift(res.data[i]);
              }
              this.page = this.page + 1;
            } else {
              this.showWarning("没有更多对话了");
            }
          }, 500);
          break;
        case "talkText":
        case "talkImage":
          if (res.data) {
            this.list.push(res.data);
            setTimeout(() => {
              this.toBottom();
            }, 100);
          }
          break;
        case "closeProblem":
          this.info.state = 2;
          this.list.push(res.data);
          this.$message.success({
            message: "本次咨询已结束,感谢您的耐心解答",
            center: true,
          });
          break;
      }
    },
    wsOpen() {
      this.connect = true;
      socket.send(this.task, "openProblem", { id: this.id });
      setTimeout(() => this.getTalk(), 300);
    },
    wsError() {
      this.connect = false;
      this.showWarning("会话连接失败");
    },
    wsClose() {
      this.connect = false;
    },
    selectFile() {
      this.$refs.fileInput.dispatchEvent(new MouseEvent("click"));
    },
    fileChange() {
      let files = this.$refs.fileInput.files;
      let number = files.length;
      if (number > 0) {
        this.tips.s1 = true;
        this.tips.text = "正在准备";
        setTimeout(() => {
          this.tips.s2 = true;

          let plan = 0;
          let check = 0;
          let list = [];
          let reader = new FileReader();
          reader.onloadend = () => {
            list.push(reader.result);
            check++;
          };
          let interval = setInterval(() => {
            if (plan == check) {
              if (check <= number - 1) {
                plan++;
                this.tips.text = "正在提取 " + (check + 1) + " / " + number;
                reader.readAsDataURL(files[check]);
              } else {
                this.upload(list);
                clearInterval(interval);
              }
            }
          }, 1000);
        }, 100);
      }
    },
    upload(list) {
      this.tips.text = "正在上传";
      setTimeout(() => {
        this.tips.text = "上传完成";
      }, 1000);
      setTimeout(() => {
        this.tips.s2 = false;
      }, 1500);
      setTimeout(() => {
        this.tips.s1 = false;
      }, 2000);
      socket.send(this.task, "talkImage", {
        list: list,
      });
    },
    closeChat() {
      this.$confirm(
        "结束咨询后本次会话将被锁定封存,如需继续沟通请勿结束咨询.",
        "结束咨询",
        {
          confirmButtonText: "确认结束",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        socket.send(this.task, "closeProblem");
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave() {
    this.task.close();
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === "ChatInfo") this.init();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.chat-head {
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  width: 100%;
}

.chat-head .title {
  align-items: center;
  display: flex;
}

.chat-head .title > span {
  margin-left: 10px;
  font-size: 18px;
}

.btn {
  background-color: #f4f4f4;
  font-size: 16px;
  color: #222;
}

.btn:hover {
  background-color: #e2e2e2;
  color: #222;
}

.btn:active,
.btn.active {
  background-color: #d4d3d3;
  color: #222;
}

.chat-body {
  display: flex;
}

.chat-content {
  background-color: #f4f4f4;
  height: calc(100vh - 85px);
  width: calc(100% - 400px);
  border-radius: 8px;
  margin-right: 10px;
}

.chat-info {
  width: 390px;
}

.bottom-bat {
  padding: 10px;
  display: flex;
}

.bottom-tools {
  margin-left: 10px;
}

.off {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  color: #f56c6c;
  display: flex;
  height: 80px;
}

.loading {
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-out 0.3s;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  position: fixed;
  display: flex;
  height: 100vh;
  z-index: 999;
  width: 100vw;
  left: 0;
  top: 0;
}

.loading.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.loading .el-loading-spinner .path {
  stroke: #ffffff;
}
</style>